<template>
  <main>
    <section class="section">
      <div class="container">
        <div class="content">
          <div class="field">
            <sqr-button label="auth_refresh" color="primary" @click="refresh()"/>
          </div>
          <div class="field" v-if="token">
            <p>token</p>
            <input :value="token" class="input" />
            <pre>{{ user }}</pre>
            <pre>{{ claims }}</pre>
          </div>

          <div class="field has-addons">
            <div class="control">
              <input v-model="customToken" class="input" />
            </div>
            <div class="control">
              <sqr-button
                label="auth_login"
                color="primary"
                @click="customLogin()"
                :is-loading="customLoading"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
export default {
  name: 'Auth',
  data() {
    return {
      user: {},
      token: '',
      claims: {},
      customToken: '',
      customLoading: false
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh () {
      const user = this.$fb().auth().currentUser;
      if (user) {
        this.user = user;
        this.token = await user.getIdToken(true);
        this.claims = await user.getIdTokenResult(true);
      } else {
        this.user = null,
        this.token = '',
        this.claims = {};
      }
    },
    async customLogin() {
      try {
        this.customLoading = false;
        const custom = this.customToken;
        const user = await this.$fb()
          .auth()
          .signInWithCustomToken(custom);
      } catch (error) {
        console.log(error);
      } finally {
        this.customLoading = false;
      }
    }
  }
};
</script>
